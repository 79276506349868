import { PaletteMode } from '@mui/material';
import Container from '@mui/material/Container';
import { createTheme } from '@mui/material/styles';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import React from 'react';
import useLocalStorageState from 'use-local-storage-state';
import Footer from './Footer';
import Header from './Header';
import UnhandledExceptionBoundary from './UnhandledExceptionBoundary';
import { Outlet } from 'react-router-dom';

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const Layout = (): React.ReactElement => {
    const [mode, setMode] = useLocalStorageState<'light' | 'dark'>('color_theme', { defaultValue: 'dark' });

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'));
            }
        }),
        []
    );

    const theme = createTheme(
        {
            palette: {
                mode,
                primary: {
                    main: '#880808',
                    dark: '#880808'
                },
                secondary: {
                    main: '#00008B'
                }
            },
            components: {
                MuiChip: {
                    styleOverrides: {
                        root: ({ ownerState }) => ({
                            backgroundColor: ownerState.variant === 'outlined' ? 'white' : ''
                        })
                    }
                }
            },
            mixins: {
                toolbar: {
                    background: mode === 'light' ? '#880808' : '#880808'
                }
            }
        },
        [mode]
    );

    return (
        <UnhandledExceptionBoundary>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <Header />
                    <Container
                        maxWidth={false}
                        component="main"
                        sx={{
                            height: '100%',
                            width: '100%',
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : 'black'
                        }}
                        style={{ padding: 0, margin: 0 }}>
                        <UnhandledExceptionBoundary>
                            <Outlet />
                        </UnhandledExceptionBoundary>
                    </Container>
                    <Footer />
                </ThemeProvider>
            </ColorModeContext.Provider>
        </UnhandledExceptionBoundary>
    );
};

export default Layout;
