import { Card, CardContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';

const Message = ({ message, previousMessageTime }: { message: any; previousMessageTime?: string }): React.ReactElement => {
    const theme = useTheme();

    let dateFormat = 'DD.MM.YYYY HH:mm:ss';
    if (/[0-9]{4}-[0-9]{2}-[0-9]{2}[[:blank:]][0-9]{2}:[0-9]{2}/.test(previousMessageTime ?? '')) {
        dateFormat = 'YYYY-MM-DD HH:mm';
    }

    const sincePrevious =
        previousMessageTime && !previousMessageTime.includes('ago')
            ? dayjs(message.timestamp, dateFormat).from(dayjs(previousMessageTime, dateFormat), true)
            : undefined;

    return (
        <Card
            sx={{
                marginBottom: theme.spacing(2),
                textAlign: message.party.toLowerCase() === 'victim' ? 'left' : 'right',
                backgroundColor: message.party.toLowerCase() === 'victim' ? theme.palette.secondary.main : theme.palette.primary.main
            }}>
            <CardContent>
                <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color={theme.palette.common.white} gutterBottom>
                    {message.party}
                </Typography>
                <Typography variant="body2" color={theme.palette.common.white} sx={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                    {message.content}
                </Typography>
                <Typography sx={{ fontSize: 10, paddingTop: theme.spacing(0.5) }} color={theme.palette.common.white}>
                    {message.timestamp}{' '}
                    {sincePrevious && sincePrevious !== 'NaN years' && (
                        <>
                            <br />
                            <i>{sincePrevious} after previous message</i>
                        </>
                    )}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default Message;
