import { Route, RouteObject, createRoutesFromElements } from 'react-router-dom';
import Layout from './Layout';
import ChatViewer from './chat-viewer/ChatViewer';

const Routes = (): RouteObject[] => {
    return createRoutesFromElements(
        <Route path="/" element={<Layout />}>
            <Route index element={<ChatViewer />} />
            <Route path=":name" element={<ChatViewer />} />
        </Route>
    );
};

export default Routes;
