import ErrorIcon from '@mui/icons-material/Error';
import { Alert, Box, Chip, CircularProgress, Divider, Grid, List, ListItemButton, ListItemText, Paper, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import chatIndex from '../data/chats.json';
import { uniqueValues } from '../tools/uniqueValues';
import Message from './Message';

const Title = ({ name }: { name: string }): React.ReactElement => {
    const theme = useTheme();

    const parts = name.split(/-(.*)/s);

    return (
        <Stack direction="row" spacing={theme.spacing(2)}>
            <Chip label={parts.at(0)} />
            <Typography>{parts.at(1)}</Typography>
        </Stack>
    );
};

const ChatViewer = (): React.ReactElement => {
    const { name } = useParams();
    const theme = useTheme();
    const navigate = useNavigate();

    const [chat, setChat] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [invalidChat, setInvalidChat] = useState(false);
    const [selectedChatName, setSelectedChatName] = useState('');

    const actors = useMemo(() => {
        return chatIndex.map((i) => i.actor).filter(uniqueValues);
    }, [chatIndex]);

    const [filter, setFilter] = useState<string[]>([]);

    useEffect(() => {
        if (!name || name.trim() === '') {
            return;
        }

        selectChat(name);
    }, [name]);

    const selectChat = (selectedChat: string) => {
        fetch(`/chats/${selectedChat}.json`)
            .then((r) => r.json())
            .then((r) => {
                setChat(r);
                setSelectedChatName(selectedChat);
                setInvalidChat(false);
            })
            .catch((_e) => setInvalidChat(true))
            .finally(() => setLoading(false));
    };

    const toggleFilter = (a: string) => {
        if (filter.includes(a ?? '')) {
            setFilter([...filter.filter((f) => f !== a)]);
        } else {
            setFilter([...filter, a]);
        }
    };

    return (
        <Grid container columnSpacing={theme.spacing(2)} maxHeight="100%">
            <Grid item md={5} lg={3} maxHeight="100%" overflow="auto" padding={theme.spacing(2)}>
                <Paper sx={{ padding: theme.spacing(2) }} style={{ marginLeft: theme.spacing(2) }}>
                    <Typography sx={{ color: theme.palette.grey[400] }}>Filter</Typography>
                    <Stack direction="row" flexWrap="wrap" gap={theme.spacing(1)}>
                        {actors.map((a) => (
                            <Chip key={a} label={a} color={filter.includes(a ?? '') ? 'primary' : 'default'} onClick={() => toggleFilter(a ?? '')} />
                        ))}
                    </Stack>
                </Paper>
                <Divider sx={{ marginTop: theme.spacing(2) }} />
                <List dense>
                    {chatIndex
                        .filter((i) => filter.length === 0 || filter.includes(i.actor))
                        .map((c, i) => (
                            <ListItemButton key={i} onClick={() => navigate(`/${c.name}`)}>
                                <ListItemText
                                    primary={<Title name={c.name} />}
                                    primaryTypographyProps={{ color: theme.palette.text.primary }}
                                    secondary={c.id}
                                />
                            </ListItemButton>
                        ))}
                </List>
            </Grid>
            <Grid item md={7} lg={5} maxHeight="100%" overflow="auto" padding={theme.spacing(2)} style={{ paddingLeft: theme.spacing(4) }}>
                {loading && <CircularProgress size="10em" />}
                {invalidChat && (
                    <Alert color="error" icon={<ErrorIcon />}>
                        Invalid entry
                    </Alert>
                )}
                {!loading && !invalidChat && !chat && (
                    <Alert color="info" severity="info">
                        No chat selected
                    </Alert>
                )}
                {!loading && !invalidChat && chat && (
                    <>
                        <Typography variant="h4" component="h2" sx={{ color: theme.palette.text.primary }}>
                            {chat.chat_id}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary, marginBottom: theme.spacing(4) }}>
                            <Title name={selectedChatName} />
                        </Typography>
                        <Box>
                            {chat.messages.map((m: any, i: number, a: any[]) => (
                                <Message key={i} message={m} previousMessageTime={i > 0 ? a.at(i - 1).timestamp : undefined} />
                            ))}
                        </Box>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default ChatViewer;
