import GitHubIcon from '@mui/icons-material/GitHub';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

const Header = (): React.ReactElement => {
    const theme = useTheme();

    return (
        <AppBar position="sticky">
            <Toolbar>
                <img src="/logo.png" alt="Logo" style={{ maxHeight: '34px', maxWidth: '90%', padding: '10px' }} />
                <Typography variant="h6" sx={{ padding: theme.spacing(1) }}>
                    Ransomchats Viewer
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="GitHub Repository"
                    title="GitHub Repository"
                    sx={{ mr: 2 }}
                    component="a"
                    href="https://github.com/Casualtek/Ransomchats">
                    <GitHubIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
